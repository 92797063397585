import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      userIsInitialized: state => state.user.isInitialized,
    }),
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
    }),
    isUserAuthenticated() {
      return this.userIsInitialized && this.isAuthenticated
    },
  },
  watch: {
    isUserAuthenticated: {
      immediate: true,
      handler() {
        if (!this.isUserAuthenticated) {
          return
        }

        this.$router.push(this.localePath({ name: 'customer-account' })).catch(() => {})
      },
    },
  },
}
