
  import { ValidationObserver } from 'vee-validate'

  export default {
    components: {
      ValidationObserver,
    },
    props: {
      isInFlyIn: {
        type: Boolean,
        default: false,
      },
      customLoginButtonText: {
        type: String,
        default: null,
      },
      customCreateAccountButtonText: {
        type: String,
        default: null,
      },
      checkUserGroup: {
        type: Boolean,
        default: false,
      },
      prefilledEmailAddress: {
        type: String,
        default: null,
      },
      noticeText: {
        type: String,
        default: null,
      },
    },
    emits: [
      'create-account-click',
      'forgot-password-click',
    ],
    data() {
      return {
        email: '',
        errorMessage: null,
        formIsValid: false,
        password: '',
        pending: false,
      }
    },
    computed: {
      uspsLoginForm() {
        return this.$staticDataService.cmsLayoutsDefault?.usps.loginForm
      },
      loginButtonText() {
        return this.customLoginButtonText ?? this.$t('login')
      },
      createAccountButtonText() {
        return this.customCreateAccountButtonText ?? this.$t('create_account')
      },
      showNoticeText() {
        return this.noticeText?.length
      },
    },
    mounted() {
      if (this.prefilledEmailAddress) {
        this.email = this.prefilledEmailAddress
        this.$refs.passwordInput?.$el?.querySelector('input')?.focus()
      }
    },
    methods: {
      async checkFormValidity() {
        this.formIsValid = await this.$refs.loginForm?.validate({ silent: true })
      },
      async onSubmit() {
        if (!this.formIsValid) {
          return
        }

        this.pending = true

        try {
          await this.$userService.login({
            email: this.email,
            password: this.password,
            scopeapp: this.checkUserGroup,
            closeFlyIn: this.isInFlyIn,
          })

          if (!this.isInFlyIn) {
            return this.$router.push(this.localePath({ name: 'customer-account' })).catch(() => {})
          }
        } catch (error) {
          if (error?.message) {
            this.errorMessage = error?.message
          } else {
            this.errorMessage = this.$t('something_went_wrong')
          }
        } finally {
          this.pending = false
        }
      },
    },
  }
