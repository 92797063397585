import { render, staticRenderFns } from "./login-form.vue?vue&type=template&id=70e06f9c"
import script from "./login-form.vue?vue&type=script&lang=js"
export * from "./login-form.vue?vue&type=script&lang=js"
import style0 from "./login-form.vue?vue&type=style&index=0&id=70e06f9c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmailInput: require('/opt/build/repo/src/client/components/email-input/email-input.vue').default,PasswordInput: require('/opt/build/repo/src/client/components/password-input/password-input.vue').default,AppAlert: require('/opt/build/repo/src/client/components/app-alert/app-alert.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default,Usps: require('/opt/build/repo/src/client/components/usps/usps.vue').default})
