
  import redirectToAccountIfAuthenticated from '~/mixins/redirect-to-account-if-authenticated'
  import formatCanonicalForRoute from '~/lib/format-canonical-for-route'

  export default {
    mixins: [redirectToAccountIfAuthenticated],
    middleware: ['customer-account-redirect'],
    head() {
      return formatCanonicalForRoute(this.$route.path)
    },
  }
