import enforceTrailingSlash from './enforce-trailing-slash'

/**
 * @param {string} path needs to be defined as /en/clothing
 * @returns {Object}
 */
export default function(path) {
  const canonicalLink = enforceTrailingSlash(`${process.env.baseUrl}${path}`)

  return {
    link: [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: canonicalLink,
      },
    ],
  }
}
